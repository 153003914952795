import Router from 'next/router';
import { lang } from '../../../functions/lang';
import ModalTitleText from '../../../pages/usuario/components/ModalTitleText';
import Button from '../../Button';
import ButtonBorder from '../../ButtonBorder';
import styles from './styles.module.css';
import { getUrls } from '../../../services/wl/getUrls';
const SignInUp = (props) => {
  console.log({ SignInUp: props.wl });
  const logoEmpresa = getUrls(props.wl?.logo_empresa_2?.data?.attributes?.url);
  return (
    <>
      <section className={styles.splah}>
        <img src={logoEmpresa} />
      </section>
      <section className={`${styles.body} ${styles.container}`}>
        <h2>{props.wl?.Onboarding?.titulo_bem_vindo}</h2>
        <div
          className={styles.bottomBorderExternal}
          style={{
            background: '#f2f5f2',
          }}
        >
          <div className={styles.bottomBorderInternal}></div>
        </div>

        <Button
          name="Não tenho conta"
          handleOnClick={() => {
            Router.push({
              pathname: '/calculadora-desconto/',
              query: { type: 'form', app: 1 },
            });
          }}
          wl={props.wl}
        />

        <ButtonBorder
          name="Já tenho conta"
          handleOnClick={() => {
            Router.push('/usuario/entrar');
          }}
          wl={props.wl}
        />
        <div className={styles.terms}>
          <span>Ao prosseguir significa que você aceita nossos</span>
          <span>
            <a>
              <ModalTitleText
                src-icon-close={lang('usuario/index.icon-close', '')}
                content={lang('usuario/index.termos-uso', '')}
              />
            </a>{' '}
            e
            <a>
              <ModalTitleText
                src-icon-close={lang('usuario/index.icon-close')}
                content={lang('usuario/index.politica-privacidade', '')}
                stylesTitle={{ background: 'red' }}
              />
            </a>
          </span>
        </div>
      </section>
    </>
  );
};

export default SignInUp;
