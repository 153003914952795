import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import { Header } from './Header/index';
import OnboardingCarousel from './OnboardigCarousel';
import styles from './styles.module.css';
import ButtonBorder from '../ButtonBorder';
import Button from '../Button';
import { useState, useEffect } from 'react';
import SplashScreen from '../SplashScreen';
import SignInUp from './SignInUp';
import { getUrls } from '../../services/wl/getUrls';

/**
 *
 * @param {viewport: string -  desktop | mobile} props */

const Onboarding = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const secureRedirect = (uri) => {
    try {
      setIsLoading(true);
      Router.push(`${uri ? uri : '/usuario'}`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const imgLoad = getUrls(props.wl?.splash?.data?.attributes?.url);

  return (
    <>
      <Head>
        <title>{`${props.wl?.empresa} - Onboarding`}</title>
      </Head>
      <div className={styles.responsivePage}>
        <div className={styles.left}>
          <img className={styles.background} src={props.bgImg} />
          <Header skipPage={props.skipPage} wl={props.wl} />
          <article className={styles.mascot}>
            <img src={props.mascotImg} />
          </article>

          <article
            className={`${styles.text} ${styles.container} ${styles.section__itens}`}
          >
            <h1
              style={{
                color: props.wl.cor_tema_primaria
                  ? props.wl.cor_tema_primaria
                  : '',
              }}
              className={`${styles.title}  mobile__h2`}
            >
              {props.titlePage}
            </h1>

            <span className={`mobile__p2 ${styles.description}`}>
              {props.descPage}
            </span>

            <span className={`mobile__p2 ${styles.description}`}>
              {props.secondDescPage}
            </span>

            <article className={styles['grid-button']}>
              {isLoading ? (
                <img
                  alt="no-img"
                  width={100}
                  height={100}
                  style={{
                    margin: 'auto',
                  }}
                  src={imgLoad}
                />
              ) : (
                <>
                  <div className={styles.carousel}>
                    <OnboardingCarousel page={props.onboarding} wl={props.wl} />
                  </div>
                  <div className={styles.buttons}>
                    {props.prevUri ? (
                      <ButtonBorder
                        name="Anterior"
                        arrowLeft={true}
                        handleOnClick={() => {
                          secureRedirect(props.prevUri);
                        }}
                        colorButtonBorder={props.corPrimaria}
                        wl={props.wl}
                      />
                    ) : (
                      ''
                    )}
                    <Button
                      name="Próximo"
                      arrowRight={true}
                      handleOnClick={() => {
                        secureRedirect(props.nextUri);
                      }}
                      wl={props.wl}
                    />
                  </div>
                </>
              )}
            </article>
          </article>
        </div>

        <div className={styles.right}>
          <SignInUp wl={props.wl} />
          {props.wl?.Calculadora?.habilitar_captura_fornecedor && (
            <div
              className={styles.cardFornecedor}
              style={{
                background: props.wl.cor_tema_4,
              }}
            >
              <button
                onClick={() =>
                  Router.push(
                    `/calculadora-desconto?type=form-fornecedor&&app=1`
                  )
                }
              >
                {props.wl.Calculadora.texto_captura_fornecedor}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Onboarding;
