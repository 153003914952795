import Router, { useRouter } from 'next/router';
import Link from 'next/link';
import { Fragment, useState } from 'react';
import styles from './styles.module.css';
/**
 * Component recebe via props component "page" que indica a página do onboarding de referencia
 */

const getNextPage = (pageNumber) => {
  if (pageNumber > 5) {
    // if (pageNumber === 6) {
    //   return "/usuario";
    // }

    return false;
  }

  return `/onboarding/onboarding-${pageNumber + 1}`;
};

const getPrevPage = (pageNumber) => {
  if (pageNumber < 3) {
    if (pageNumber === 2) {
      return '/';
    }

    return false;
  }

  return `/onboarding/onboarding-${pageNumber - 1}`;
};

export default function OnboardingCarousel(props) {
  //array para armazenar os status dos slides
  let pages = [];

  //preenchimento do array com 5 itens atribuindo status "1" para a página selecionado e status "0" para as outras páginas
  for (let i = 0; i < 6; i++) {
    props.page === i + 1 ? pages.push(1) : pages.push(0);
  }

  const nextLink = getNextPage(props.page);

  const prevLink = getPrevPage(props.page);

  console.log({ Carousel: props.wl });

  return (
    <section>
      <div className={styles.container__slider__chevrons}>
        {/* Previous Button */}
        {!prevLink ? (
          <div className={styles.prevButton}></div>
        ) : (
          // Icon anterior
          <Link href={prevLink}>
            <a className={styles.prevButton}>
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.99521 0.671336C8.25556 0.931686 8.25556 1.3538 7.99521 1.61415L1.60948 7.99988L7.99521 14.3856C8.25556 14.646 8.25556 15.0681 7.99521 15.3284C7.73486 15.5888 7.31275 15.5888 7.05241 15.3284L0.195262 8.47129C-0.0650874 8.21094 -0.0650874 7.78883 0.195262 7.52848L7.05241 0.671336C7.31275 0.410987 7.73486 0.410987 7.99521 0.671336Z"
                  fill={props.wl?.cor_tema_5}
                />
              </svg>
            </a>
          </Link>
        )}

        {/* Others Center Links */}
        <div className={styles.container__slider}>
          {pages.map((page, index) => {
            return page === 1 ? (
              <svg
                key={index}
                width="24"
                height="10"
                viewBox="0 0 24 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="24"
                  height="10"
                  rx="5"
                  fill={props.wl?.cor_tema_primaria}
                />
              </svg>
            ) : (
              <svg
                key={index}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5" cy="5" r="5" fill={props.wl?.cor_tema_4} />
              </svg>
            );
          })}
        </div>

        {/* Next Button */}
        {!nextLink ? (
          <div className={styles.nextButton}></div>
        ) : (
          // Icon próximo
          <Link href={nextLink}>
            <a className={styles.nextButton}>
              <svg
                width="8"
                height="16"
                viewBox="0 0 8 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.195262 0.671336C0.455612 0.410987 0.877722 0.410987 1.13807 0.671336L7.99521 7.52848C8.25556 7.78883 8.25556 8.21094 7.99521 8.47129L1.13807 15.3284C0.877722 15.5888 0.455612 15.5888 0.195262 15.3284C-0.0650874 15.0681 -0.0650874 14.646 0.195262 14.3856L6.581 7.99988L0.195262 1.61415C-0.0650874 1.3538 -0.0650874 0.931686 0.195262 0.671336Z"
                  fill={props.wl?.cor_tema_5}
                />
              </svg>
            </a>
          </Link>
        )}
      </div>
    </section>
  );
}
