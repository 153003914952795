import Router, { useRouter } from 'next/router';
import { Fragment } from 'react';
// import Image from "next/image";
import styles from './styles.module.css';
import { getUrls } from '../../../services/wl/getUrls';
export function Header(props) {
  return (
    <Fragment>
      <header className={styles.headerContainer}>
        <div className="cursor__pointer" onClick={() => Router.push(`/`)}>
          <img
            src={
              props.wl.Onboarding.header_color
                ? getUrls(`${props.wl?.logo_empresa_2.data.attributes.url}`)
                : getUrls(`${props.wl?.logo_empresa.data.attributes.url}`)
            }
            alt={props.wl?.empresa}
          ></img>
        </div>

        {props.skipPage ? (
          <div
            className="cursor__pointer"
            onClick={() => Router.push(`/usuario`)}
            style={{
              zIndex: 100,
            }}
          >
            <a
              style={{
                color: props.wl.Onboarding.header_color
                  ? props.wl?.cor_tema_primaria
                  : '#FFFFFF',
              }}
            >
              Pular
            </a>
          </div>
        ) : (
          ''
        )}
      </header>
    </Fragment>
  );
}
